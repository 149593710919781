import React, { useState } from "react"
import { css } from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FormError } from "../../components/formError"
import { StripeLogo } from "../../components/stripeLogo"

const wrapperStyles = css`
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
`

const poemStyles = css`
  margin-top: 30px;
  font-size: 1em;
  font-style: italic;
  text-align: center;
  color: rgba(89, 89, 89, 0.7);
`

const rangeStyles = css`
  width: 100%;
  margin-top: 50px;
  -webkit-appearance: none;
  background: transparent;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #ac7b84;
    height: 32px;
    width: 32px;
    margin-top: -8px;
    border-radius: 99px;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background: rgba(225, 187, 195, 1);
  }
`

const buttonStyles = css`
  grid-area: button;
  border: none;
  height: 120px;
  width: 100%;
  color: #fff;
  border-radius: 8px;
  background-color: rgba(225, 187, 195, 1);
  cursor: pointer;
  transition: background-color 200ms;
  outline: none;
  font-size: 2em;
  font-weight: 600;
  margin-top: 30px;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(225, 187, 195, 0.8);
  }
`

const Registry = () => {
  const [error, setError] = useState(null)
  const [amount, setAmount] = useState(100)
  // hack becuase window doesn't exist in nodejs
  const windowGlobal = typeof window !== "undefined" && window
  const stripe = windowGlobal && windowGlobal.Stripe(process.env.STRIPE_API_KEY)

  const onClick = () =>
    stripe
      .redirectToCheckout({
        items: [{ sku: process.env.STRIPE_PRODUCT_CODE, quantity: amount / 5 }],
        successUrl:
          window.location.protocol + "//elsaandainsley.com/registry/success",
        cancelUrl:
          window.location.protocol + "//elsaandainsley.com/registry/cancelled",
        submitType: "donate",
      })
      .then(({ error }) => {
        if (error) {
          setError(error.message)
        }
      })

  const onChange = ({ target }) => {
    setAmount(target.value)
  }

  return (
    <Layout>
      <SEO title="Registry" />

      {error && <FormError>error</FormError>}

      <section css={wrapperStyles}>
        <p css={poemStyles}>
          We don’t want to offend but we have it all, <br />
          All household goods and so much more. <br />
          To save you shopping, sit back and rest, <br />A gift of currency is
          our request. <br />
          Don’t go overboard or rob any banks, <br />
          Any little thing will make us smile with thanks. <br />
          We supply the wishing well, <br />
          No wrapping, an envelope who can tell. <br />
          Now that we have saved you all the fuss, <br />
          We’d love it if you would come and celebrate with us!
        </p>

        <input
          type="range"
          min="50"
          max="1000"
          value={amount}
          step="5"
          css={rangeStyles}
          onChange={onChange}
        />

        <button role="link" onClick={onClick} css={buttonStyles}>
          Gift £{amount}
        </button>

        <div
          css={css`
            text-align: center;
            margin-top: 30px;
          `}
        >
          <StripeLogo />
        </div>
      </section>
    </Layout>
  )
}

export default Registry
